import { IAposta } from "./Aposta";
import { NOME } from "./schemas";
import * as Yup from 'yup';

export enum EStatus {
    Rascunho    = 'RA',
    Aberto      = 'AB',
    Cancelado   = 'CA',
    Andamento   = 'EA',
    Finalizado  = 'FN'
}

export enum ETipo {
    Ganhador        = 'GP',
    MenorPontuacao  = 'MP',
    ComissaoInterna = 'CI',
    Outros          = 'OT'
}

export interface IJogoComissao {
    CD_JOGO_COMISSAO?   : number;
    CD_JOGO             : number;
    PCE_PERCENTUAL      : number;
    TIPO                : ETipo;
    NOME?               : string;
    APOSTA?             : IAposta[];
}

export interface IJogo {
    CD_JOGO?            : number;
    NOME                : string;
    STATUS              : EStatus;
    DT_CADASTRO?        : string | Date;
    DT_ALTERACAO?       : string | Date;
    DT_INICIO?          : string | Date;
    DT_FIM?             : string | Date;
    VLR_APOSTA          : number;
    OBSERVACAO?         : string;
    CD_TIPO_JOGO        : number;
    CD_PESSOA_CADASTRO? : number;
    COMISSAO?           : IJogoComissao[];
    QTD_APOSTAS?        : number;
    QTD_PREMIO?         : number;
};

export const schemaJogo = Yup.object().shape({   
    NOME            : NOME,
    STATUS          : Yup.string().required('Deve ser preenchido'),
    VLR_APOSTA      : Yup.number().min(0.01, 'Deve ser maior que zero').required('Deve ser preenchido'),
});

export const schemaJogoComissao = Yup.object().shape({
    TIPO            : Yup.string().required('Deve ser preenchido'),
    PCE_PERCENTUAL  : Yup.number().min(0.01, 'Deve ser maior que zero').max(100, 'Deve ser menor que cem').required('Deve ser preenchido'),
});