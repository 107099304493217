import { useOutletContext } from "react-router-dom";
import { IUseJogo } from "../Hook/useJogo";
import useMask from "../../../Components/useMask";
import Tabela from "../../../Components/tabela";
import { IJogoComissao } from "../../../Interfaces/Jogo";
import { Badge, Container, Form } from "react-bootstrap";
import ModalConfirmacao from "../../../Components/ModalConfirmacao";
import { useContext, useMemo, useState } from "react";
import CartaoPule from "../../../Components/pule/cartaoPule";
import useColorScheme from "../../../Components/aposta/colorScheme";
import { EPuleStatus } from "../../../Interfaces/Pule";
import { CtxAppParams } from "../../../Contexts/ctxAppParams";
import { EPermissao } from "../../../Constants/permissao";
import usePremioController from "../../../Controllers/Premio";
import { CtxPremioAposta } from "../../../Contexts/ctxPremioAposta";

const JogoPremioInfo = () => {
    const Jogo = useOutletContext<IUseJogo>();
    const AppParams = useContext(CtxAppParams);
    const QtdAposta = (Jogo.Datasource.Item.QTD_APOSTAS ?? 0);
    const VlrAposta = (Jogo.Datasource.Item.VLR_APOSTA ?? 0);
    const TotAposta = QtdAposta * VlrAposta;
    const formatter = useMask();
    const [showModal, setShowModal] = useState<boolean>(false);

    const [selecionado, setSelecionado] = useState<IJogoComissao>();
    const Colors = useColorScheme();
    const maxDigits = useMemo(() => {
        return Jogo.TipoJogo?.VLR_MAX.toString().length;
    }, [Jogo.TipoJogo?.VLR_MAX]);
    const Gerencial = !!AppParams.Permissoes?.find(permissao => permissao.REGRA === String(EPermissao.GERENCIA_PULE));


    const Premio = usePremioController({updateQueryKey: "Premio"});

    return (
        <CtxPremioAposta.Provider value={{
            ControllerPremio: Premio,
            Comissao: selecionado
        }}>
            <Container className="verticalCenter">
                <ModalConfirmacao
                    titulo={'Visualização das apostas premiadas'}
                    show={showModal} 
                    onHide={setShowModal}
                    variant={"none"}
                    onSubmitHandler={() => {}}
                >
                    <Container className="verticalCenter">
                    {
                        selecionado && selecionado.APOSTA && selecionado.APOSTA.length > 0 ?
                            <CartaoPule
                                Pule={{NOME: selecionado.NOME, STATUS: EPuleStatus.Finalizado, APOSTA: selecionado.APOSTA}}
                                Jogo={Jogo.Datasource.Item}
                                MaxDigits={maxDigits}
                                formatter={formatter}
                                Colors={Colors}
                                ModoExibicao="ListaPremio"
                                Gerencial={Gerencial}
                            />
                        :
                            <>Pule não encontrada</>
                    }
                    </Container>
                </ModalConfirmacao>

                <div className="mt-3 w-100">
                    <Tabela
                        dados={Jogo.Datasource.Item.COMISSAO?.filter(comissao => comissao.TIPO !== 'CI') ?? []} 
                        handleClick={(e: IJogoComissao) => {
                            if (e.APOSTA && e.APOSTA.length > 0) {
                                setSelecionado(e);
                                setShowModal(true);
                            }
                        }}
                        colunas={[
                            {
                                Header: 'Prêmio',
                                id: "nome",
                                Cell: (row: any) : any => {
                                    const comissao : IJogoComissao = row.row.original;
                                    return (
                                        <div>
                                            <div>{comissao.NOME}</div>
                                            {comissao.APOSTA && comissao.APOSTA.length > 0 && 
                                            <small>{comissao.APOSTA?.length} Aposta(s) Premiada(s)</small>}                                         
                                        </div>
                                    )
                                }                                
                            },  
                            {
                                Header: 'Valor Total',
                                id: "valortot",
                                Cell: (row: any) : any => {
                                    const comissao : IJogoComissao = row.row.original;
                                    const Percentual = (Number(comissao.PCE_PERCENTUAL ?? 0) / 100);
                                    return (
                                        <div className="d-flex w-100 justify-content-center"><h5><Badge pill bg="light" text="success">{formatter.setMaskMoney(TotAposta * Percentual, 'R$')}</Badge></h5></div>                            
                                    )
                                }
                            },
                            {
                                Header: 'Odd Máx.',
                                id: "odd",
                                Cell: (row: any) : any => {
                                    const comissao : IJogoComissao = row.row.original;
                                    const Percentual = (Number(comissao.PCE_PERCENTUAL ?? 0) / 100);
                                    return (
                                        <div className="d-flex w-100 justify-content-center"><Badge pill bg="success">{Number((TotAposta * Percentual) / VlrAposta).toFixed(2)}x</Badge></div>                            
                                    )
                                }
                            },
                        ]}
                    />
                </div>
                <div className="mt-3 w-100">
                    <Form.Group className="mt-3">
                        <Form.Label>Informações gerais:</Form.Label>
                        <Form.Control 
                            name={'OBSERVACAO'}
                            type={'text'}
                            as={'textarea'}
                            disabled
                            value={Jogo.Datasource.Item.OBSERVACAO ?? ""}
                        />
                    </Form.Group>     
                </div>
            </Container>
        </CtxPremioAposta.Provider>
    )
}

export default JogoPremioInfo;