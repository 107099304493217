import { useOutletContext } from "react-router-dom";
import Tabela from "../../../Components/tabela";
import { IUseJogo } from "../Hook/useJogo";
import { EPuleStatus, IPule } from "../../../Interfaces/Pule";
import { Button, Card, Container, Dropdown, DropdownButton, Form, InputGroup, Nav, Tab } from "react-bootstrap";
import { FaEye, FaTimes } from "react-icons/fa";
import { useContext, useMemo, useState } from "react";
import useMask from "../../../Components/useMask";
import ModalConfirmacao from "../../../Components/ModalConfirmacao";
import CartaoPule from "../../../Components/pule/cartaoPule";
import useColorScheme from "../../../Components/aposta/colorScheme";
import { FaTrashCan } from "react-icons/fa6";
import { CtxAppParams } from "../../../Contexts/ctxAppParams";
import { EPermissao } from "../../../Constants/permissao";

const JogoGerenciador = () => {
    const AppParams = useContext(CtxAppParams);
    const Jogo = useOutletContext<IUseJogo>();
    const formatter = useMask();
    const [showModal, setShowModal] = useState<boolean>(false);
    const Colors = useColorScheme();
    const [aba, setAba] = useState<'Pendente' | 'Confirmado'>('Confirmado');
    const TotPceComissao = Jogo.Datasource.Item.COMISSAO
        ?.filter(comissao => comissao.TIPO === 'CI')
        .reduce((acc, comissao) => acc + (comissao.PCE_PERCENTUAL ?? 0), 0);
    const maxDigits = useMemo(() => {
        return Jogo.TipoJogo?.VLR_MAX.toString().length;
    }, [Jogo.TipoJogo?.VLR_MAX]);

    const [tipoFiltro, setTipoFiltro] = useState<'NOME' | 'NOME_CAMBISTA' | 'CD_JOGO_PULE'>('NOME');
    const [filtro, setFiltro] = useState<string>('');

    return (
        <Container className="verticalCenter">
            <div className="d-flex w-100 mt-3 justify-content-around">
                <InputGroup>
                    <DropdownButton
                        variant="outline-secondary"
                        title={
                            (() => {
                                switch (tipoFiltro) {
                                case 'NOME':
                                    return 'Cliente'
                                case 'NOME_CAMBISTA':
                                    return 'Cambista'
                                case 'CD_JOGO_PULE':
                                    return 'Nr. Pule'
                                default:
                                    return 'erro'
                                }
                            })()
                        }
                        id="TPFiltroPesquisa"
                    >
                        <Dropdown.Item eventKey={'JogoGerenciador'} key={'a'} onClick={() => setTipoFiltro('NOME')}>Cliente</Dropdown.Item>
                        <Dropdown.Item eventKey={'JogoGerenciador'} key={'b'} onClick={() => setTipoFiltro('NOME_CAMBISTA')}>Cambista</Dropdown.Item>
                        <Dropdown.Item eventKey={'JogoGerenciador'} key={'c'} onClick={() => setTipoFiltro('CD_JOGO_PULE')}>Nr. Pule</Dropdown.Item>
                    </DropdownButton>
                    <Form.Control
                        placeholder="Filtro..."
                        aria-label="Filtro"
                        size="lg"
                        value={filtro}
                        onChange={(e : React.ChangeEvent<HTMLInputElement>) => setFiltro(e.target.value) }
                    />
                </InputGroup>
            </div>

            <ModalConfirmacao
                titulo={'Visualização de Pule'}
                show={showModal} 
                onHide={setShowModal}
                variant={"none"}
                onSubmitHandler={() => {}}
            >
                <Container className="verticalCenter">
                {
                    Jogo.Aposta.Controller.Buscar.isSuccess && Jogo.Aposta.Controller.Buscar.data ?
                        <CartaoPule
                            Pule={Jogo.Aposta.Datasource.Item}
                            Jogo={Jogo.Datasource.Item}
                            MaxDigits={maxDigits}
                            formatter={formatter}
                            Colors={Colors}
                            ModoExibicao="ListaJogo"
                        />
                    :
                        <>Pule não encontrada</>
                }
                </Container>
            </ModalConfirmacao>

            <Tab.Container
                activeKey={aba}
                onSelect={(eventKey) => setAba(eventKey as any)} 
            >
                <Card className="mt-3 w-100">
                    <Card.Header>
                        <Nav variant="tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="Confirmado">Confirmados</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Pendente">Pendentes</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Card.Header>

                    <Card.Body className="d-flex w-100 justify-content-center flex-wrap">
                        <Tabela
                            dados={
                                aba === "Confirmado" 
                                    ? [Jogo.Aposta.Datasource.TotalJogoConfirmado]
                                    : [Jogo.Aposta.Datasource.TotalJogoPendente]
                            }
                            colunas={[
                                {
                                    Header: 'Quantidade de Apostas',
                                    id: "qtdApostas",
                                    Cell: (row: any) => ((row.row.original).QTD_APOSTA ?? 0)
                                },
                                {
                                    Header: 'Total Arrecadado',
                                    id: "totalArrecadado",
                                    Cell: (row: any) => (formatter.setMaskMoney(((row.row.original).VLR_APOSTA ?? 0), 'R$'))
                                },
                                {
                                    Header: 'Comissão',
                                    id: "totalComissao",
                                    Cell: (row: any) => (formatter.setMaskMoney(((row.row.original).VLR_APOSTA ?? 0) * (TotPceComissao ?? 0) / 100, 'R$'))
                                }
                            ]}
                        />

                        <Tabela
                            dados={
                                aba === "Confirmado" 
                                    ? Jogo.Aposta.Datasource.TotaisTodosJogoConfirmado 
                                    : Jogo.Aposta.Datasource.TotaisTodosJogoPendente
                            }
                            colunas={[
                                {
                                    Header: 'Cambista',
                                    id: "cambista",
                                    accessor: "NOME_CAMBISTA"
                                },
                                {
                                    Header: 'Pules',
                                    id: "qtdAPuleCambista",
                                    accessor: "QTD_PULE"
                                },
                                {
                                    Header: 'Apostas',
                                    id: "qtdApostaCambista",
                                    accessor: "QTD_APOSTA"
                                },
                                {
                                    Header: 'Arrecadado Bruto',
                                    id: "arrecadado",
                                    Cell: (row: any) => (formatter.setMaskMoney((row.row.original).TOTAL ?? 0, 'R$'))
                                },
                                {
                                    Header: 'Comissão',
                                    id: "comissao",
                                    Cell: (row: any) => (formatter.setMaskMoney(((row.row.original).TOTAL ?? 0) * (TotPceComissao ?? 0) / 100, 'R$'))
                                },
                            ]}
                        />

                        <Tabela 
                            dados={
                                aba === "Confirmado" 
                                    ? Jogo.Aposta.Datasource.ListaTodosJogoConfirmado.filter((data) => {
                                        return String(data[tipoFiltro])?.toLowerCase().indexOf(String(filtro)?.toLowerCase()) !== -1
                                    })
                                    : Jogo.Aposta.Datasource.ListaTodosJogoPendente.filter((data) => {
                                        return String(data[tipoFiltro])?.toLowerCase().indexOf(String(filtro)?.toLowerCase()) !== -1
                                    })
                            } 
                            colunas={[
                                {
                                    Header: 'Pule',
                                    id: "pule",
                                    accessor: "CD_JOGO_PULE"
                                },
                                {
                                    Header: 'Nome',
                                    id: "nome",
                                    accessor: "NOME"
                                }, 
                                {
                                    Header: 'Total',
                                    id: "valor",
                                    Cell: (row: any) => (formatter.setMaskMoney((row.row.original as IPule).VLR_APOSTA ?? 0, 'R$'))
                                }, 
                                {
                                    Header: 'Apostas',
                                    id: "apostas",
                                    Cell: (row: any) => ((row.row.original as IPule).QTD_APOSTA ?? 0)
                                },
                                {
                                    id: "Acoes",
                                    Header: "Ações",
                                    Cell: (row: any) : any => 
                                        <div className="w-100 d-flex justify-content-evenly">
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    const selecionado : IPule = row.row.original;
                                                    Jogo.Aposta.Controller.Buscar.mutateAsync({CD_JOGO_PULE: selecionado.CD_JOGO_PULE}, {
                                                        onSuccess: () => {
                                                            setShowModal(true);
                                                        }
                                                    });
                                                }}
                                            >
                                                <FaEye />
                                            </Button>

                                            <Button
                                                variant="danger"
                                                size="sm"
                                                disabled={!AppParams.Permissoes?.find(permissao => permissao.REGRA === String(EPermissao.DESCONFIRMA_PULE))}
                                                onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    const selecionado : IPule = row.row.original;
                                                    if (aba === 'Confirmado') {
                                                        if (window.confirm(`Tem certeza que deseja desconfirmar a pule ${selecionado.CD_JOGO_PULE} no nome de ${selecionado.NOME}?`)) {
                                                            Jogo.Aposta.Controller.Alterar.mutateAsync({
                                                                ...selecionado, 
                                                                STATUS: EPuleStatus.Aberto, 
                                                                CD_CAMBISTA: selecionado.CD_CAMBISTA 
                                                                    ? selecionado.CD_CAMBISTA 
                                                                    : -1
                                                            }, {
                                                                onSuccess: () => {
                                                                    // Jogo.Aposta.Controller.ListarTodosJogo.refetch();
                                                                }
                                                            });
                                                        };
                                                    } else {
                                                        if (window.confirm(`Tem certeza que deseja excluir a pule ${selecionado.CD_JOGO_PULE} no nome de ${selecionado.NOME}?`)) {
                                                            Jogo.Aposta.Controller.Deletar.mutateAsync(selecionado);
                                                        };
                                                    }
                                                }}
                                            >
                                                {aba === 'Confirmado' ? <FaTimes /> : <FaTrashCan />}
                                            </Button>
                                        </div>
                                },
                                {
                                    Header: 'Cambista',
                                    id: "cambista",
                                    accessor: "NOME_CAMBISTA"
                                }, 
                            ]} 
                        />   
                    </Card.Body>
                </Card>
            </Tab.Container>
        </Container>
    )
}

export default JogoGerenciador;