import { useOutletContext } from "react-router-dom";
import { IUseJogo } from "../Hook/useJogo";
import Tabela from "../../../Components/tabela";
import { Badge, Col, Container, Form } from "react-bootstrap";
import { IAposta, ILancamento } from "../../../Interfaces/Aposta";
import { useContext, useMemo, useState } from "react";
import { EStatus, ETipo } from "../../../Interfaces/Jogo";
import { CtxAppParams } from "../../../Contexts/ctxAppParams";
import { EPermissao } from "../../../Constants/permissao";
import ModalConfirmacao from "../../../Components/ModalConfirmacao";
import useColorScheme from "../../../Components/aposta/colorScheme";
import CartaoPuleAposta from "../../../Components/pule/cartaoPuleAposta";
import usePremioController from "../../../Controllers/Premio";

const JogoResultado = () => {
    const Jogo = useOutletContext<IUseJogo>();
    const AppParams = useContext(CtxAppParams);
    const Gerencial = !!AppParams.Permissoes?.find(permissao => permissao.REGRA === String(EPermissao.GERENCIA_PULE));
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selecionado, setSelecionado] = useState<IAposta>({} as IAposta);
    const [premio, setPremio] = useState<number>();
    const Colors = useColorScheme();
    const Premio = usePremioController({updateQueryKey: 'Premio'});

    const maxDigits = useMemo(() => {
        return Jogo.TipoJogo?.VLR_MAX.toString().length;
    }, [Jogo.TipoJogo?.VLR_MAX]);

    const colunasAposta = useMemo(() => [
        {
            Header: 'Nome',
            id: "nome",
            Cell: (row: any) : any => row.row.original['NOME']
        },
        {
            Header: 'Aposta',
            id: "aposta",
            Cell: (row: any) : any => {
                const Lancamentos : ILancamento[] = row.row.original['LANCAMENTO'];
                
                return Lancamentos && Lancamentos.map(lancamento => {
                    return (
                        <Badge 
                            pill 
                            className="mx-1"
                            style={{fontSize: '1rem'}}
                            bg={lancamento.ACERTO ? 'primary' : 'light'}
                            text={lancamento.ACERTO ? 'light' : 'dark'}
                        >
                            {lancamento.NUMERO.toString().padStart(maxDigits, '0')}
                        </Badge>
                    )
                })
            }
        },
        {
            Header: 'Pontuação',
            id: "pontuacao",
            Cell: (row: any) : any => {
                return (
                    <Badge 
                        style={{fontSize: '1rem'}}
                        bg={row.row.original['PONTO'] === Jogo.TipoJogo?.QTD_MAX ? 'success' : 'dark'}
                        text={row.row.original['PONTO'] === Jogo.TipoJogo?.QTD_MAX ? 'light' : 'light'}
                    >
                        {row.row.original['PONTO']}
                    </Badge>
                )
            }
        },
    ], [maxDigits, Jogo.TipoJogo?.QTD_MAX]);

    const colunasConcurso = useMemo(() => [
        {
            Header: 'Concurso',
            id: "concurso",
            Cell: (row: any) : any => row.row.original['NM_CONCURSO']
        },
        {
            Header: 'Lançamentos',
            id: "lancamentos",
            Cell: (row: any) : any => {
                const Lancamentos : number[] = row.row.original['LANCAMENTO'];
                
                return Lancamentos && Lancamentos.map(lancamento => {
                    return (
                        <Badge 
                            pill 
                            className="mx-1"
                            style={{fontSize: '1rem'}}
                            bg={'primary'}
                        >
                            {lancamento.toString().padStart(maxDigits, '0')}
                        </Badge>
                    )
                })
            }
        },
        {
            Header: 'Data',
            id: "data",
            Cell: (row: any) : any => row.row.original['DT_CONCURSO']
        }
    ], [maxDigits]);    

    return (
        <Container className="verticalCenter">
            <ModalConfirmacao
                titulo={'Atribuir prêmio'}
                show={showModal} 
                onHide={setShowModal}
                disabledConfirm={Premio.Cadastrar.isLoading || !premio}
                onSubmitHandler={() => {Premio.Cadastrar.mutateAsync(
                    {CD_JOGO_COMISSAO: premio, CD_JOGO_PULE_APOSTA: selecionado.CD_JOGO_PULE_APOSTA}, {
                        onSuccess: () => {
                            setShowModal(false);
                            setPremio(undefined);
                            Jogo.Controller.Buscar.mutate({CD_JOGO: Jogo.Datasource.Item.CD_JOGO});
                        }
                    }
                )}}
            >
                <Container className="verticalCenter">
                {
                    selecionado && ((selecionado.CD_JOGO_PULE_APOSTA ?? 0) > 0) ?
                        <>
                            <CartaoPuleAposta
                                Aposta={selecionado}
                                Colors={Colors}
                                MaxDigits={maxDigits}
                                Detalhado={true}
                                Gerencial={Gerencial}
                            />

                            <Col>
                                <Form.Group className="mt-3">
                                    <Form.Label>Prêmio:</Form.Label>
                                    <Form.Select 
                                        name={'CD_JOGO_COMISSAO'}
                                        value={premio ?? ""}
                                        onChange={ e => {
                                            setPremio(Number(e.target.value));
                                        }}
                                        size="lg"
                                    >
                                        <option key={''} value={''} disabled={true}>Selecione</option>
                                        {Jogo.Controller.Buscar.isSuccess && Jogo.Controller.Buscar.data && Jogo.Datasource.Item && 
                                        Jogo.Datasource.Item.COMISSAO && Jogo.Datasource.Item.COMISSAO.length > 0 && 
                                        Jogo.Datasource.Item.COMISSAO.filter(premio => premio.TIPO === ETipo.Outros).map((premio) => (
                                            <option key={premio.CD_JOGO_COMISSAO} value={premio.CD_JOGO_COMISSAO}>
                                                {premio.NOME}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </>
                    :
                        <>Aposta não encontrada</>
                }
                </Container>
            </ModalConfirmacao>

            {
                [EStatus.Aberto, EStatus.Rascunho].includes(Jogo.Datasource.Item.STATUS) &&
                    <div className="mt-3">
                        O Jogo ainda não começou. Os Resultados serão exibidos assim que for iniciado.
                    </div>
                
            }         
            {
                Jogo.Aposta.Datasource.ListaJogo && Jogo.Aposta.Datasource.ListaJogo.length > 0 &&
                <div className="mt-3 w-100">
                    <Tabela
                        dados={Jogo.Aposta.Datasource.ListaJogo} 
                        colunas={colunasAposta}
                        handleClick={(e: IAposta) => {
                            if (Gerencial) {
                                setSelecionado(e);
                                setShowModal(true);
                            }
                        }}
                    />
                </div>
            }
            {
                Jogo.Concurso.Datasource.ListaJogo && Jogo.Concurso.Datasource.ListaJogo.length > 0 &&
                <div className="mt-3 w-100">
                    <Tabela
                        dados={Jogo.Concurso.Datasource.ListaJogo} 
                        colunas={colunasConcurso}
                    />
                </div>
            }            
        </Container>
    )
}

export default JogoResultado;